import React, { useRef } from 'react'

import { Button } from '@coq-trotteur/coq-common-components/Atoms/Button/Button'

import Dropdown, { useDropdown } from '~components/Common/Dropdown/Dropdown'
import { useModal } from '~components/Common/Modal/Modal'
import { useAuthContext } from '~contexts/authContext/authContext'
import EditablePagePickerModal from '../../EditablePagePickerModal/EditablePagePickerModal'

import './AdminPageSidebarMenu.scss'

const AdminPageSidebarMenu = () => {
	return (
		<div className="AdminPageSidebarMenu">
			<VisitButton />
			<EditButton />
			<BackToAccoundButton />
			<OptionsButton />
		</div>
	)
}

export default AdminPageSidebarMenu

const VisitButton = () => (
	<a
		href="/"
		target="_blank"
		style={{
			marginBottom: 10,
			display: 'block'
		}}
	>
		<Button
			style={{
				width: '100%',
				textAlign: 'center'
			}}
		>
			Voir le site
		</Button>
	</a>
)

const BackToAccoundButton = () => (
	<Button
		onClick={() => {
			window.open('http://activite.coq-trotteur.com/')
		}}
		style={{
			textAlign: 'center'
		}}
	>
		Revenir sur mon compte
	</Button>
)

const EditButton = () => {
	const editablePagePickerModal = useModal()

	return (
		<>
			<EditablePagePickerModal {...editablePagePickerModal} />
			{/* <Link to="/admin"> */}
			<Button
				theme="dark"
				style={{
					marginBottom: 10,
					width: '100%',
					textAlign: 'center'
				}}
				onClick={() => {
					editablePagePickerModal.open()
				}}
			>
				Éditer mon site
			</Button>
			{/* </Link> */}
		</>
	)
}

const OptionsButton = () => {
	const menuDropdown = useDropdown()
	const authContext = useAuthContext()

	const rootRef = useRef<HTMLDivElement>()

	return (
		<div style={{ position: 'relative', marginTop: 10 }} ref={rootRef}>
			<Button
				style={{
					width: '100%',
					textAlign: 'center'
				}}
				onClick={() => {
					if (!menuDropdown.isOpen) {
						menuDropdown.open()
					} else {
						menuDropdown.close()
					}
				}}
			>
				<span>
					<i className="fas fa-cog" /> Options / aide
				</span>
			</Button>

			<Dropdown
				{...menuDropdown}
				horizontalPosition="right"
				verticalPosition="top"
				rootRef={rootRef}
			>
				<a href="mailto:aide@coq-trotteur.com">
					<button>
						<span className="icon">
							<i className="fas fa-exclamation-circle" />
						</span>
						<span>
							{' '}
							Besoin d'aide ?{' '}
							<small>
								Ecrivez à : <u>aide@coq-trotteur.com</u>
							</small>
						</span>
					</button>
				</a>

				<hr />

				<button
					className="loggout"
					onClick={() => {
						authContext.logout()
					}}
				>
					<span className="icon">
						<i className="fas fa-sign-out-alt" />
					</span>
					<span>Se déconnecter</span>
				</button>
			</Dropdown>
		</div>
	)
}
