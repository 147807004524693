import React, { useEffect, useRef, useState } from 'react'
import Select from '~components/Common/Basics/Select/Select'
import Modal from '~components/Common/Modal/Modal'
import { useGlobalContext } from '~contexts/globalContext'
import { TWebsitePage } from '~types/Website.types'

import './SiteMapPageEditionModal.scss'

const SiteMapPageEditionModal: React.FC<any> = ({
	isOpen,
	close,
	onDone,
	payload
}: {
	isOpen: boolean
	close: () => void
	onDone: (data: TWebsitePage) => void
	payload: TWebsitePage
}) => {
	const [slug, setSlug] = useState(null)
	const { website } = useGlobalContext()
	const slugInputRef = useRef<HTMLInputElement>(null)

	const pageParent = usePageParent(payload)

	const getParentPageSlug = () => {
		if (!pageParent.hasParent) return ''
		if (!pageParent.parent) return ''
		return website.getPage(pageParent.parent).slug + '/'
	}

	const getFullSlug = () => {
		const parentSlug = getParentPageSlug()
		if (!parentSlug) return slug
		return `${parentSlug}${slug}`
	}

	useEffect(() => {
		if (!isOpen) setSlug('')
		else {
			window.requestAnimationFrame(() => {
				slugInputRef.current.focus()
				slugInputRef.current.select()
			})
		}
	}, [isOpen])

	useEffect(() => {
		if (isOpen && payload) {
			const parentSlug = payload.parent?.id
				? website.getPage(payload.parent?.id).slug
				: ''

			setSlug(cleanSlug(payload.slug.replace(parentSlug, '')))
		}
	}, [payload, isOpen])

	const isSlugAlreadyExists = () => {
		if (!isOpen) return

		const fullSlug = getFullSlug()

		if (getFullSlug() === 'admin') return true

		const find = website.pages.find((page) => page.slug === `${fullSlug}`)
		if (find === undefined) return false
		if (find.id === payload.id) return false
		return true
	}

	const slugAlreadyExists = isSlugAlreadyExists()

	const handleSave = () => {
		onDone({
			...payload,
			slug: getFullSlug(),
			parent:
				pageParent.hasParent && pageParent.parent ? pageParent.parent : null
		})
	}
	return (
		<Modal
			isOpen={isOpen}
			close={close}
			allowQuitOnClickOverlay={true}
			className="SiteMapPageEditionModal"
		>
			{isOpen && (
				<>
					<div className={`slug-edition ${slugAlreadyExists ? 'error' : ''}`}>
						<PageParent pageParent={pageParent} />
						<div className="slug-line">
							{document.location.origin}/{getParentPageSlug()}
							<input
								ref={slugInputRef}
								type="text"
								value={slug}
								onChange={(e) => {
									setSlug(
										e.target.value.replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()
									)
								}}
								spellCheck={false}
							/>
							<div className="error-tooltip">Cette page existe déjà</div>
						</div>
					</div>

					<div className="buttons">
						<button
							className="cancel"
							onClick={() => {
								close()
							}}
						>
							Annuler
						</button>

						<button onClick={handleSave} className="save">
							Sauvegarder
						</button>
					</div>
				</>
			)}
		</Modal>
	)
}

const usePageParent = (page) => {
	const [hasParent, setHasParent] = useState(false)
	const [parent, setParent] = useState(page?.parent?.id || null)

	useEffect(() => {
		if (page?.parent?.id) {
			setHasParent(true)
		} else {
			setHasParent(false)
		}
		setParent(page?.parent?.id || null)
	}, [page])

	return {
		hasParent,
		setHasParent,
		parent,
		setParent
	}
}

const PageParent = ({ pageParent }) => {
	const { website } = useGlobalContext()

	return (
		<div className="PageParent">
			<div>
				<span>Cette page est-elle une page enfant / sous page</span>
				<Select
					value={pageParent.hasParent}
					onChange={pageParent.setHasParent}
					options={[
						{
							name: 'Oui',
							value: true
						},
						{
							name: 'Non',
							value: false
						}
					]}
				/>
			</div>
			{pageParent.hasParent && (
				<div>
					<span>À quelle page appartient-elle ?</span>

					<Select
						value={pageParent.parent}
						onChange={pageParent.setParent}
						options={website.pages
							.filter(({ slug }) => slug !== '')
							.map(({ slug, id: value }) => ({
								value,
								name: `/${slug}`
							}))}
					/>
				</div>
			)}
		</div>
	)
}

export default SiteMapPageEditionModal

const cleanSlug = (slug: string) => slug.replace(/^\//, '')
