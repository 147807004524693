import React from 'react'
// import { Link } from 'gatsby'

import SiteMapPageDropDown from './SiteMapPageDropDown/SiteMapPageDropDown'
import { SiteMapPageProps } from './SiteMapPage.types'

import './SiteMapPage.scss'

const SiteMapPage = ({
	page,
	setEnabled,
	handleEdit,
	simplified
}: SiteMapPageProps) => {
	return (
		<div
			className={`SiteMapPage ${
				page.enabled || simplified ? 'enabled' : 'disabled'
			}`}
			style={{
				position: 'relative'
			}}
		>
			{!simplified && (
				<div className="disabled-page">
					<i className="mdi mdi-eye-off-outline" /> Page désactivée
				</div>
			)}

			<a
				className="name"
				href={`/editor?p=${page.id}`}
				title="Visiter la page"
				target="_blank"
				rel="noreferrer"
			>
				<span className="slug">/{page.slug}</span>
			</a>
			{!simplified && (
				<div className="buttons">
					<SiteMapPageDropDown
						page={page}
						setEnabled={setEnabled}
						handleChangeAddress={handleEdit}
					/>
				</div>
			)}
		</div>
	)
}

export default SiteMapPage
