import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'
import { useConfirmationModalContext } from '~components/Common/Modal/ConfirmationModal/ConfirmationModal'
import { useGlobalContext } from '~contexts/globalContext'
import useClickOutside from '~hooks/useClickOutside'

import './SiteMapPageDropDown.scss'

const SiteMapPageDropDown = ({ page, setEnabled, handleChangeAddress }) => {
	const [isOpen, setIsOpen] = useState(false)
	const rootRef = useRef<HTMLDivElement>()
	const confirmationModal = useConfirmationModalContext()

	useClickOutside(isOpen, setIsOpen, rootRef)

	const closeDropdown = () => {
		setIsOpen(false)
	}

	return (
		<div className="SiteMapPageDropDown" ref={rootRef}>
			<button
				onClick={() => {
					setIsOpen(!isOpen)
				}}
				title={''}
				// disabled={page.slug === ''}
			>
				<i className="fas fa-cog" />
			</button>

			<div className={`dropdown ${isOpen ? 'active' : ''}`}>
				<Link
					to={`/editor?p=${page.id}`}
					className="slug"
					title="Visiter la page"
				>
					<i className="mdi mdi-lead-pencil" /> Editer
				</Link>
				<a
					href={`/${page.slug}`}
					target="_blank"
					className="slug"
					title="Visiter la page"
					rel="noreferrer"
				>
					<i className="mdi mdi-account" /> Voir en tant que visiteur
				</a>

				{page.slug !== '' && (
					<a
						onClick={() => {
							setIsOpen(false)
							handleChangeAddress()
						}}
						style={{
							cursor: 'pointer'
						}}
						target="_blank"
						className="slug"
						title="Visiter la page"
						rel="noreferrer"
					>
						<i className="mdi mdi-lead-pencil" /> Modifier l'adresse
					</a>
				)}

				{page.slug !== '' &&
					(page.enabled ? (
						<button
							onClick={() => {
								closeDropdown()

								confirmationModal.open({
									acceptCallback: () => {
										setEnabled(false)
									},
									title: 'Voulez-vous vraiment désactiver cette page ?',
									description: (
										<span>
											La page <strong>/{page.slug}</strong> ne sera plus visible
											pour les visiteurs. Il sera possible de la réactiver plus
											tard.
										</span>
									),
									theme: 'danger',
									acceptText: 'Désactiver la page',
									refuseText: 'Annuler'
								})
							}}
						>
							<i className="mdi mdi-eye-off" />
							Désactiver
						</button>
					) : (
						<button
							onClick={() => {
								closeDropdown()
								setEnabled(true)
							}}
						>
							<i className="mdi mdi-eye" />
							Activer
						</button>
					))}

				{page.slug !== '' && (
					<>
						<hr />
						<SiteMapPageDropDownRemoveButton
							page={page}
							closeDropdown={closeDropdown}
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default SiteMapPageDropDown

const SiteMapPageDropDownRemoveButton = ({ page, closeDropdown }) => {
	const globalContext = useGlobalContext()
	const confirmationModal = useConfirmationModalContext()

	return (
		<button
			onClick={() => {
				closeDropdown()
				confirmationModal.open({
					acceptCallback: () => {
						globalContext.website.removePage(page)
					},
					title: 'Voulez-vous vraiment supprimer cette page ?',
					description: (
						<span>
							La page <strong>/{page.slug}</strong> sera définitivement
							supprimée, il ne vous sera plus possible de revenir en arrière.
						</span>
					),
					theme: 'danger',
					acceptText: 'Supprimer la page',
					refuseText: 'Annuler'
				})
			}}
		>
			<i className="mdi mdi-trash-can" /> Supprimer
		</button>
	)
}
