import React, { useRef, useState } from 'react'
import useClickOutside from '~hooks/useClickOutside'

import { TDropdownProps } from './Dropdown.types'

import './Dropdown.scss'

export const useDropdown = () => {
	const [isOpen, setIsOpen] = useState(false)

	return {
		isOpen,
		close: () => {
			setIsOpen(false)
		},
		open: () => {
			setIsOpen(true)
		}
	}
}

const Dropdown = ({
	children,
	isOpen,
	close,
	className = '',
	horizontalPosition = 'left',
	verticalPosition = 'bottom',
	theme = 'light',
	rootRef
}: TDropdownProps) => {
	const dropdownRootRef = useRef()

	useClickOutside(isOpen, close, rootRef || dropdownRootRef)

	return (
		<div
			ref={dropdownRootRef}
			className={`Dropdown horizontal-position-${horizontalPosition} vertical-position-${verticalPosition} ${
				isOpen ? 'active' : ''
			} theme-${theme} ${className}`}
		>
			{children}
		</div>
	)
}

export default Dropdown
