import React, { useEffect } from 'react'
import { navigate } from 'gatsby-link'

import { useModal, useModalWithPayload } from '~components/Common/Modal/Modal'
import { useGlobalContext } from '~contexts/globalContext'
import SiteMapPage from './SiteMapPage/SiteMapPage'
import SiteMapPageEditionModal from './SiteMapPageEditionModal/SiteMapPageEditionModal'

import CreatePageModal from './CreatePageModal/CreatePageModal'
import { TWebsitePage } from '~types/Website.types'
import { generateNewPage } from '~hooks/useWebsite'

import './RouteSiteMap.scss'

const RouteSiteMap = () => {
	const { website } = useGlobalContext()
	const pageEditionModal = useModalWithPayload()
	const createPageModal = useModal()

	useEffect(() => {
		website.refreshData()
	}, [])

	return (
		<div className="RouteSiteMap">
			<CreatePageModal {...createPageModal} />
			<SiteMapPageEditionModal
				{...pageEditionModal}
				onDone={(page: TWebsitePage) => {
					if (website.getPage(page.id)) {
						website.updatePage(page)
					} else {
						website.addPage(page).then((page) => {
							navigate(`/editor?p=${page.id}`)
						})
					}
					pageEditionModal.close()
				}}
			/>

			<header>
				<div>
					<h2>Pages du site</h2>

					<p>Cliquez sur une page pour la visionner ou la modifier</p>
				</div>
				<div>
					<button
						className="create-page"
						onClick={() => {
							pageEditionModal.open({
								...generateNewPage()
							})
						}}
					>
						<i className="mdi mdi-plus-circle" /> Créer une nouvelle page
					</button>
				</div>
			</header>

			<div className="pages">
				{website.pages
					.sort((a, b) => a.slug.localeCompare(b.slug))
					.map((page) => (
						<SiteMapPage
							page={page}
							key={page.id}
							handleEdit={() => {
								pageEditionModal.open(page)
							}}
							setEnabled={(enabled) => {
								website.updatePage({
									...page,
									enabled
								})
							}}
						/>
					))}
			</div>
		</div>
	)
}

export default RouteSiteMap
