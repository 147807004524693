import React from 'react'
import usePlan from '~hooks/usePlan'

import './AdminPlanLabel.scss'

const AdminPlanLabel = () => {
	const { percentage, planDiskUse } = usePlan()

	return (
		<div className="AdminPlanLabel">
			<span>Plan</span>
			{percentage > 50 && (
				<>
					<div className="pers_bar">
						<div
							className={'evolution' + (percentage >= 90 ? ' red' : '')}
							style={{ width: percentage + '%' }}
						/>
					</div>
					<span className="rate">{planDiskUse}</span>
				</>
			)}
		</div>
	)
}

export default AdminPlanLabel
