import { useEffect, useState } from 'react'
import { usePlanQueries } from '~queries/plan.queries'
type TPLanData = {
	stockage: number
	maxStockage: number
}
type TUsePlan = {
	percentage: number
	planDiskUse: string
	planData: TPLanData
	userPlan: TUserPlan
}

export type TUserPlan = {
	type: 'Start' | 'Pro' | 'Expert'
	expirationDate?: string
	websiteId?: string
	id?: string
	transactionReference?: string
	validation_id?: string
	client?: {
		firstname?: string
		lastname
		email
		phone
	}
}

const usePlan = (): TUsePlan => {
	const planQueries = usePlanQueries()
	const [userPlan, setUserPlan] = useState<TUserPlan>({ type: 'Start' })
	const [planData, setPlanData] = useState<TPLanData>({
		stockage: 0,
		maxStockage: 500 * 1024 * 1024 //500Mo
	})

	const [percentage, setPercentage] = useState(0)
	const [planDiskUse, setPlanDiskUse] = useState('0Mo utilisés sur 500Mo')

	useEffect(() => {
		planQueries.getUserPlan().then((userPlan) => {
			setUserPlan(userPlan)
			let { maxStockage } = planData

			if (['Expert', 'pro'].includes(userPlan.type)) {
				maxStockage = 5 * 1024 * 1024 * 1024 // 5Go
			}

			planQueries.getStockage().then((res) => {
				if (res > 0) {
					const percentage = Math.round((res / maxStockage) * 100)
					let used = `${(percentage / 100) * 500}Mo utilisés sur 500Mo`
					if (['Expert', 'Pro'].includes(userPlan.type)) {
						used = `${(percentage / 100) * 5}Go utilisés sur 5Go`
					}
					setPlanData({
						maxStockage,
						stockage: res
					})
					setPercentage(percentage)
					setPlanDiskUse(used)
				}
			})
		})
	}, [])

	useEffect(() => {
		//
	}, [])

	return {
		percentage,
		planData,
		planDiskUse,
		userPlan
	}
}

export default usePlan
