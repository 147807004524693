import { gql } from 'apollo-boost'

import { doGraphqlMutation, doGraphqlQuery } from '~queries/common.queries'
import { useAuthContext } from '~contexts/authContext/authContext'

export const usePlanQueries = () => {
	const authContext = useAuthContext()

	const websiteId = process.env.GATSBY_WEBSITE_ID

	const getUserPlan = async () => {
		const query = gql(`
			query FetchWSPLAN($websiteId: ID!) {
				fetchWebsiteReservationPlan(websiteId: $websiteId) {
					transactionReference 
					validation_id
					client {
						firstname
						lastname
						email
						phone
					}
					id
					type
					expirationDate
					websiteId
				}
			}
		`)

		const variables = {
			websiteId
		}

		const { fetchWebsiteReservationPlan } = await doGraphqlQuery({
			query,
			variables,
			token: authContext.token,
			apiVersion: 2
		})

		return fetchWebsiteReservationPlan
	}

	const getAllPlans = async () => {
		const query = gql(`
		query FetchActivityMarketItems {
			fetchWebsitePlans {
					id
			type
			price
			annualBill
			}
		}
		`)

		const variables = {
			websiteId
		}

		const { fetchWebsitePlans } = await doGraphqlQuery({
			query,
			variables,
			token: authContext.token,
			apiVersion: 2
		})

		return fetchWebsitePlans
	}

	const getStockage = async () => {
		const query = gql(`
			query calcUserStockage {
				calcUserStockage
			}
		`)

		const variables = {}

		const { calcUserStockage } = await doGraphqlQuery({
			query,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return calcUserStockage
	}

	const processPlanPayment = async (websiteReservationPlanInput) => {
		const mutation = gql(`
			mutation OrderWebsiteReservationPlan(
				$websiteReservationPlanInput: WebsiteReservationPlanInput!
			) {
				orderWebsiteReservationPlan(
					websiteReservationPlanInput: $websiteReservationPlanInput
				) {
					redirectionUrl
					paReqMessage
					redirectionData
					redirectionStatusCode
					messageVersion
					seal
					validation_id
				}
			}
		`)

		const variables = {
			websiteReservationPlanInput
		}

		const { orderWebsiteReservationPlan } = await doGraphqlMutation({
			mutation,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return orderWebsiteReservationPlan
	}

	return {
		getStockage,
		getUserPlan,
		getAllPlans,
		processPlanPayment
	}
}
