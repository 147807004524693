import { Link } from '@reach/router'
import React, { useEffect, useRef, useState } from 'react'

import AdminPageSidebarMenu from './AdminPageSidebarMenu/AdminPageSidebarMenu'

import './AdminPageSidebar.scss'
import { SealIcon } from '../RouteAdminPlan/components/icons/SealIcon'
import AdminPlanLabel from './AdminPlanLabel/AdminPlanLabel'

const AdminPageSidebar = () => {
	const currentPage = window.location.pathname.replace(/\/$/, '')

	return (
		<div className="AdminPageSidebar">
			<nav>
				<Link
					to="/admin"
					className={`${currentPage === '/admin' ? 'active' : ''}`}
				>
					<i
						className="icon mdi mdi-content-copy"
						style={{
							marginRight: 2,
							width: 24
						}}
					/>
					Pages du site
				</Link>
				<ItemMenu
					path={'/admin/apparence'}
					currentPage={currentPage}
					label="Apparence"
					icon={<i className="icon mdi mdi-format-color-fill" />}
					menu={[
						{
							label: 'Thèmes',
							icon: <i className="mdi mdi-view-dashboard" />,
							link: 'apparence/mes-themes'
						},
						{
							label: 'Couleurs',
							icon: <i className="mdi mdi-view-dashboard" />,
							link: 'apparence/couleurs'
						},
						{
							label: 'Textes',
							icon: <i className="mdi mdi-view-dashboard" />,
							link: 'apparence/textes'
						}
					]}
				/>

				<ItemMenu
					path={'/admin/ecommerce'}
					currentPage={currentPage}
					label="E-Commerce"
					icon={<i className="icon mdi mdi-basket-outline" />}
					menu={[
						{
							label: 'Mes produits',
							link: 'ecommerce/mes-produits'
						},
						// {
						// 	label: 'Categories produits',
						// 	link: 'ecommerce/categories'
						// },
						{
							label: 'Mes services',
							link: 'ecommerce/mes-services'
						}
					]}
				/>
				{/* 
				<Link
					to="/admin/comptes"
					className={`${currentPage === '/admin/comptes' ? 'active' : ''}`}
				>
					<i
						className="fas fa-user-circle"
						style={{
							marginRight: 2,
							width: 24
						}}
					/>{' '}
					Comptes
				</Link> */}

				<Link
					to="/admin/SEO"
					className={`${currentPage === '/admin/SEO' ? 'active' : ''}`}
				>
					<i
						className="icon mdi mdi-rocket-outline"
						style={{
							marginRight: 2,
							width: 24
						}}
					/>
					Référencement
				</Link>
				<Link
					to="/admin/mailling"
					className={`${currentPage === '/admin/mailling' ? 'active' : ''}`}
				>
					<i
						className="icon mdi mdi-email-outline"
						style={{
							marginRight: 2,
							width: 24
						}}
					/>
					Mailling
				</Link>
				<Link
					to="/admin/ressources"
					className={`${currentPage === '/admin/ressources' ? 'active' : ''}`}
				>
					<i
						className="icon mdi mdi-book-outline"
						style={{
							marginRight: 2,
							width: 24
						}}
					/>
					Ressources et aide
				</Link>
				{/*
				<Link
					to="/admin/statistiques"
					className={`${currentPage === '/admin/statistiques' ? 'active' : ''}`}
				>
					<i
						className="icon mdi mdi-chart-bell-curve-cumulative"
						style={{
							marginRight: 2,
							width: 24
						}}
					/>
					Statistiques
				</Link>
				*/}

				<Link
					to="/admin/plan"
					className={`${currentPage === '/admin/plan' ? 'active' : ''}`}
				>
					<SealIcon
						style={{
							marginRight: 7,
							width: 19
						}}
					/>
					<AdminPlanLabel />
				</Link>
				{/* <Link
					to="/admin/restaurant"
					className={`${currentPage === '/admin/restaurant' ? 'active' : ''}`}
				>
					<SealIcon
						style={{
							marginRight: 7,
							width: 19
						}}
					/>
					Resturant
				</Link> */}
			</nav>

			<div>
				<AdminPageSidebarMenu />
			</div>
		</div>
	)
}

export default AdminPageSidebar

const ItemMenu = ({ currentPage, label, icon, menu, path }) => {
	const [isOpen, setIsOpen] = useState(
		currentPage.startsWith(path) ? true : false
	)

	const menuRef = useRef<HTMLDivElement>()

	useEffect(() => {
		if (currentPage.startsWith(path)) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	}, [currentPage])

	useEffect(() => {
		if (isOpen) {
			menuRef.current.style.height = menu.length * 47 + 'px'
		} else {
			menuRef.current.style.height = '0'
		}
	}, [isOpen])

	return (
		<div className={`ItemMenu ${isOpen ? 'active' : ''}`}>
			<button
				className={`button`}
				onClick={() => {
					setIsOpen(!isOpen)
				}}
			>
				<span className="icon">{icon}</span>
				{label}
				<span className="chevron">
					<i className="mdi mdi-chevron-down" />
				</span>
			</button>

			<div className="menu" ref={menuRef}>
				{menu.map((item, i) => (
					<Link
						to={`/admin/${item.link}`}
						key={i}
						className={`item ${
							currentPage === `/admin/${item.link}` ? 'active' : ''
						}`}
					>
						{item.label}
					</Link>
				))}
			</div>
		</div>
	)
}
