import React from 'react'
import Modal from '~components/Common/Modal/Modal'
import { useGlobalContext } from '~contexts/globalContext'

import SiteMapPage from '../RouteSiteMap/SiteMapPage/SiteMapPage'

import './EditablePagePickerModal.scss'

const EditablePagePickerModal: TEditablePagePickerModal = ({
	...modalProps
}: any) => {
	const { website } = useGlobalContext()

	return (
		<Modal
			{...modalProps}
			className="EditablePagePickerModal"
			allowQuitOnClickOverlay
		>
			<h2>Choisissez la page que vous souhaitez éditer !</h2>
			<div className="pages">
				{website.pages
					.sort((a, b) => a.slug.localeCompare(b.slug))
					.map((page) => (
						<SiteMapPage page={page} key={page.id} simplified={true} />
						// <div key={page.id}>/{page.slug}</div>
					))}
			</div>
		</Modal>
	)
}

export default EditablePagePickerModal

type TEditablePagePickerModal = React.FC<any>
