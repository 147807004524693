import React from 'react'

export const SealIcon = ({ style = {} }) => {
	return (
		<svg
			width="14"
			height="22"
			viewBox="0 0 14 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				d="M14 6.99984C14 3.14006 10.8598 0 7 0C3.14022 0 0 3.14006 0 6.99984C0 9.16877 0.991667 11.1105 2.54545 12.3953V21.0842C2.54545 21.5923 3.11304 21.8949 3.53497 21.6135L6.99768 19.3035L10.4654 21.6138C10.8848 21.8932 11.4545 21.5954 11.4545 21.0842V12.3953C13.0083 11.1105 14 9.16877 14 6.99984ZM1.27273 6.99984C1.27273 3.84188 3.84205 1.27273 7 1.27273C10.158 1.27273 12.7273 3.84188 12.7273 6.99984C12.7273 10.1578 10.158 12.7268 7 12.7268C3.84205 12.7268 1.27273 10.1578 1.27273 6.99984ZM10.1818 19.8956L7.35033 18.0093C7.13655 17.8667 6.85798 17.8669 6.64437 18.0094L3.81818 19.8947V13.2337C5.81179 14.2554 8.18821 14.2554 10.1818 13.2337V19.8956Z"
				fill="#172B49"
			/>
			<path
				d="M4.1379 9.99198C4.04874 10.5107 4.59479 10.9077 5.06096 10.663L6.9969 9.64563L8.93234 10.6636C9.39702 10.9081 9.94489 10.5125 9.8559 9.99297L9.48651 7.83746L11.0527 6.31151C11.4297 5.94411 11.2216 5.30178 10.7004 5.22605L8.53627 4.91118L7.56863 2.94989C7.33596 2.47808 6.66065 2.47726 6.42748 2.94972L5.45935 4.91052L3.29504 5.22456C2.77419 5.30012 2.56505 5.94229 2.94223 6.30985L4.50795 7.83663L4.1379 9.99198ZM5.97308 6.12209C6.18039 6.09193 6.35954 5.96184 6.45234 5.77392L6.99773 4.6694L7.54278 5.77425C7.63542 5.96201 7.81473 6.09226 8.02188 6.12243L9.24108 6.29975L8.35862 7.15967C8.20864 7.30566 8.1402 7.51646 8.17566 7.72278L8.38364 8.93717C7.20239 8.31589 7.20355 8.29037 6.9969 8.29037C6.79024 8.29037 6.79157 8.31589 5.61032 8.93651L5.81879 7.72228C5.85426 7.51596 5.78582 7.30533 5.63584 7.159L4.75371 6.29892L5.97308 6.12209Z"
				fill="#172B49"
			/>
		</svg>
	)
}
