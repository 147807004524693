import React, { Suspense, useRef, lazy, useEffect } from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import AdminPageSidebar from './AdminPageSidebar/AdminPageSidebar'

import RouteSiteMap from './RouteSiteMap/RouteSiteMap'

// import { useGlobalContext } from '~contexts/globalContext'

import { ConfirmationModalProvider } from '~components/Common/Modal/ConfirmationModal/ConfirmationModal'
import { NotificationProvider } from '~components/Common/Notification/Notification'
import { ContextMenuProvider } from '~components/Common/ContextMenu/ContextMenu'

import Container from '~components/Common/Container/Container'

import './AdminPage.scss'
// import ErrorBoundary from '~components/Common/ErrorBoundary'
const RouteAdminAccounts = lazy(
	() => import('./RouteAdminAccounts/RouteAdminAccounts')
)
const AdminSectionThemes = lazy(
	() => import('./RouteAdminTheme/AdminSectionThemes/AdminSectionThemes')
)
const AdminThemeTypo = lazy(
	() => import('./RouteAdminTheme/AdminThemeTypo/AdminThemeTypo')
)
const AdminThemeColors = lazy(
	() => import('./RouteAdminTheme/AdminThemeColors/AdminThemeColors')
)

const RouteAdminSEO = lazy(() => import('./RouteAdminSEO/RouteAdminSEO'))
const RouteAdminEcommerceServices = lazy(
	() => import('./RouteAdminEcommerceServices/RouteAdminEcommerceServices')
)
const RouteAdminEcommerceProductCategories = lazy(
	() =>
		import(
			'./RouteAdminEcommerceProductCategories/RouteAdminEcommerceProductCategories'
		)
)
const RouteAdminEcommerceProducts = lazy(
	() => import('./RouteAdminEcommerceProducts/RouteAdminEcommerceProducts')
)

const RouteAdminMailling = lazy(
	() => import('./RouteAdminMailling/RouteAdminMailling')
)
const RouteAdminResources = lazy(
	() => import('./RouteAdminResources/RouteAdminResources')
)
const RouteAdminResourcesVideos = lazy(
	() => import('./RouteAdminResourcesVideos/RouteAdminResourcesVideos')
)
const RouteAdminPlan = lazy(() => import('./RouteAdminPlan/RouteAdminPlan'))
const RouteAdminPlanPayment = lazy(
	() => import('./RouteAdminPlanPayment/RouteAdminPlanPayment')
)
const RouteAdminRestaurant = lazy(
	() => import('./RouteAdminRestaurant/RouteAdminRestaurant')
)

const Onboarding = lazy(() => import('./Onboarding/Onboarding'))

import { useGlobalContext } from '~contexts/globalContext'

import { useLocation } from '@reach/router'

import './AdminPage.scss'
import { withAuth } from '~contexts/authContext/authContext'

const AdminPage = () => {
	const contentRef = useRef<HTMLDivElement>()
	const globalContext = useGlobalContext()

	const location = useLocation()

	useEffect(() => {
		contentRef.current?.scrollTo({
			top: 0
		})
	}, [location])

	if (!globalContext.website.loaded) return null
	// if (globalContext.website.onboarding)
	// 	return (
	// 		<Suspense fallback={null}>
	// 			<Onboarding />
	// 		</Suspense>
	// 	)

	return (
		<ConfirmationModalProvider>
			<NotificationProvider>
				<ContextMenuProvider>
					<div className="AdminPage">
						<AdminPageSidebar />
						<div className="content" ref={contentRef}>
							<Container size="large">
								<Router basepath="admin">
									<RouterPage path="/" pageComponent={RouteSiteMap} />

									<RouterPage
										path="/comptes"
										pageComponent={RouteAdminAccounts}
									/>
									<RouterPage
										path="apparence/mes-themes"
										pageComponent={AdminSectionThemes}
									/>
									<RouterPage
										path="apparence/textes"
										pageComponent={AdminThemeTypo}
									/>
									<RouterPage
										path="apparence/couleurs"
										pageComponent={AdminThemeColors}
									/>
									<RouterPage
										path="ecommerce/mes-produits"
										pageComponent={RouteAdminEcommerceProducts}
									/>
									<RouterPage
										path="ecommerce/categories"
										pageComponent={RouteAdminEcommerceProductCategories}
									/>
									<RouterPage
										path="ecommerce/mes-services"
										pageComponent={RouteAdminEcommerceServices}
									/>
									<RouterPage path="SEO" pageComponent={RouteAdminSEO} />
									<RouterPage
										path="mailling"
										pageComponent={RouteAdminMailling}
									/>
									<RouterPage
										path="ressources"
										pageComponent={RouteAdminResources}
									/>
									<RouterPage
										path="ressources/videos"
										pageComponent={RouteAdminResourcesVideos}
									/>
									<RouterPage path="plan" pageComponent={RouteAdminPlan} />
									<RouterPage
										path="plan/paiement"
										pageComponent={RouteAdminPlanPayment}
									/>
									<RouterPage
										path="restaurant"
										pageComponent={RouteAdminRestaurant}
									/>
								</Router>
							</Container>
						</div>
					</div>
				</ContextMenuProvider>
			</NotificationProvider>
		</ConfirmationModalProvider>
	)
}
//

const RouterPage = (props: { pageComponent: any } & RouteComponentProps) => (
	<Suspense fallback={null}>
		<props.pageComponent />
	</Suspense>
)

export default withAuth(AdminPage)
