import React, { useEffect, useRef, useState } from 'react'
import Button from '~components/Common/Button/Button'
import Modal from '~components/Common/Modal/Modal'
import { useGlobalContext } from '~contexts/globalContext'

import './CreatePageModal.scss'

const CreatePageModal = ({ isOpen, close }) => {
	const [currentStep] = useState(0)

	const components = [CreatePageModalSlug]

	const Component: React.FC<{
		handlePrevious?: (value: any) => void
		handleNext?: (value: any) => void
	}> = components[currentStep]

	return (
		<Modal
			className="CreatePageModal"
			isOpen={isOpen}
			close={close}
			showCloseButton={true}
		>
			<Component
				handleNext={() => {
					if (currentStep === components.length - 1) {
						//
					}
				}}
			/>
		</Modal>
	)
}

export default CreatePageModal

const CreatePageModalSlug = ({ handleNext }) => {
	const [slug, setSlug] = useState(null)
	const { website } = useGlobalContext()
	const slugInputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		slugInputRef.current.focus()
		slugInputRef.current.select()
	}, [])

	// useEffect(() => {
	// 	if (isOpen && payload) setSlug(payload.slug)
	// }, [payload, isOpen])

	const isSlugAlreadyExists = () => {
		// if (!isOpen) return

		const find = website.pages.find((page) => page.slug === `${slug}`)
		if (find === undefined) return false
		return true
	}

	const slugAlreadyExists = isSlugAlreadyExists()

	return (
		<>
			<div className="CreatePageModalSlug">
				<h2>Addresse de la page</h2>

				<div className={`slug-edition ${slugAlreadyExists ? 'error' : ''}`}>
					{document.location.origin}/
					<input
						ref={slugInputRef}
						type="text"
						value={slug}
						onChange={(e) => {
							setSlug(
								e.target.value.replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()
							)
						}}
						spellCheck={false}
					/>
					<div className="error-tooltip">Cette page existe déjà</div>
				</div>
			</div>
			<div className="buttons">
				<div />
				<Button
					theme="blue"
					disabled={slugAlreadyExists || slug === null}
					onClick={() => {
						handleNext({ slug })
					}}
					textAlign="center"
				>
					<span
						style={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						Créer la page
					</span>
				</Button>
			</div>
		</>
	)
}
