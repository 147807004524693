/*
 * contextmenu Context that allow to create custom context menu at specific locations.
 *
 * How to use ? Example :
 *
 * const Exemple = () => {
 *     const myCustomMenu:TContextMenu = {
 *         items: [
 *             {
 *                 label:"hello",
 *                 icon:<i className="mdi mdi-hand-peace" />,
 *                 onClick: () => alert('hello world')
 *             }
 *         ]
 *     }
 *
 *     const contextMenu = useContextMenuContext()(myCustomMenu)
 *
 *     return (
 *         <div {...contextMenu(myCustomMenu)}>Right click here !</div>
 *     )
 * }
 */
import React, { useEffect, useRef, useState } from 'react'
import useClickOutside from '~hooks/useClickOutside'

import {
	TContextMenuButtonProps,
	TContextMenuInitializer,
	TContextMenuState
} from './ContextMenu.types'

import './ContextMenu.scss'

const contextMenuContext = React.createContext(null)

export const useContextMenuContext = () => React.useContext(contextMenuContext)

const ContextMenu = ({ isOpen, handleClose, menu }, ref) => {
	useClickOutside(isOpen, handleClose, ref)

	return (
		<div className={`ContextMenu ${isOpen ? 'active' : ''}`} ref={ref}>
			{menu?.items?.map((item, i) => (
				<ContextMenuItem key={i} item={item} handleClose={handleClose} />
			)) || null}
		</div>
	)
}

const ContextMenuContainer = React.forwardRef(ContextMenu)

const ContextMenuItem = ({ item, handleClose }: TContextMenuButtonProps) => (
	<button
		className="ContextMenuItem"
		onClick={(e) => {
			if (item.onClick && typeof item.onClick === 'function') item.onClick(e)
			handleClose()
		}}
		onMouseDown={(e) => {
			e.preventDefault()
			e.stopPropagation()
		}}
	>
		<span className="icon">{item.icon}</span>
		<span className="text">{item.label}</span>
	</button>
)

export const ContextMenuProvider = ({ children }) => {
	const contextMenuRef = useRef<HTMLDivElement>()
	const [contextMenu, setContextMenu] = useState<TContextMenuState>({
		isOpen: false,
		menu: null
	})

	useEffect(() => {
		// Close context menu on scroll.
		window.addEventListener('scroll', () => {
			setContextMenu({ isOpen: false, menu: null })
		})
	}, [])

	const handleClose = () => {
		setContextMenu({
			isOpen: false,
			menu: null
		})
	}

	// Move context menu to cursor location.
	const updateContextMenuLocation = (x: number, y: number) => {
		contextMenuRef.current.style.left = x + 'px'
		contextMenuRef.current.style.top = y + 'px'
	}

	// Allows to create custom context menus easly.
	const initializer: TContextMenuInitializer = (menu) => {
		// Can be used this way : <div {...contextMenu} />
		// or <div onContextMenu={contextMenu.onContextMenu} />.
		return {
			onContextMenu: (e) => {
				// Hide native context menu
				e.preventDefault()

				updateContextMenuLocation(e.clientX, e.clientY)

				setContextMenu({
					isOpen: true,
					menu
				})
			}
		}
	}

	return (
		<contextMenuContext.Provider value={initializer}>
			<ContextMenuContainer
				menu={contextMenu.menu}
				handleClose={handleClose}
				isOpen={contextMenu.isOpen}
				ref={contextMenuRef}
			/>
			{children}
		</contextMenuContext.Provider>
	)
}
